import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { oneSdAbi, oneSdContract, usdtAbi, usdtContract, contractAbi, contractAddress, dexoContract, dexoAbi, snimda, chainName } from "../../constants";
import Package from "../../components/Package";
import VSwitch from "../../components/vswitch/VSwitch";
import RadioButton from "../../components/RadioButton/RadioButton";
import BigNumber from "bignumber.js";
import StakingService from "../../services/staking.service";
import PackagesService from "../../services/packages.service";
import AdminService from "../../services/admin.service";
import "./packages.scss"
import { PlatformFee } from "./components/PlatformFee";

function Packages(props) {
  const {referral, moralisObj, setCurrentJob, onFlash} = props

  const { isWeb3Enabled, isAuthenticated, user, Moralis } = moralisObj
  const navigate = useNavigate();
  const [packagesStatus, setPackagesStatus] = useState([
    {id: 0, pending: false},
    {id: 1, pending: false},
    {id: 2, pending: false},
    {id: 3, pending: false},
    {id: 4, pending: false},
    {id: 5, pending: false}
  ]);
  const [checking, setChecking] = useState(false);
  const [allowance, setAllowance] = useState({usdt: 0, dexo: 0, onesd: 0});
  const [activeCurrency, setActiveCurrency] = useState('dexo');
  const [dexoUsdRate] = useState(0.0000025);
  const [userTokens, setUserTokens] = useState({usdt: 0, dexo: 0, onesd: 0});
  const [packagesHistory, setPackagesHistory] = useState([])
  const [platformFeeProblem, setPlatformFeeProblem] = useState(false)
  //const [minUsdToStakeDexo, setMinUsdToStakeDexo] = useState(0)

  const [dynamicPackages, setDynamicPackages] = useState([])

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob('Loading packages info...')
      if (!checking) checkPackagesStatus()
      setChecking(true)
    }
    if(!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated]);

  async function checkPackagesStatus() {
    try {
      await Promise.all([
        loadInvestments(),
        checkAllowance(),
        //loadSettings(),
        loadUserTokens(),
        //getMinUsdToStakeDexo(),
        checkPlatformFee(),
        getPackagesData()
      ]);
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
      setChecking(false)
    }
  }
  async function reloadData() {
    await Promise.all([
      loadInvestments(),
      checkAllowance(),
      loadUserTokens()
    ]);
  }
  async function getPackagesData() {
    const pkgs = await PackagesService.getAllPackages()
    const pkgsWithSimpleId = pkgs.data.map((pkg,indx)=>{pkg.id = indx; return pkg})
    setDynamicPackages(pkgsWithSimpleId)
  }

  async function checkPlatformFee() {
    const fn = {
      contractAddress,
      functionName: "getUserSubscriptionEndTime",
      abi: contractAbi,
      params: {
        userAddress: user.attributes.ethAddress,
      },
    };
    const _userInfo = await Moralis.executeFunction(fn);
    const feePaymentTime = parseInt(_userInfo._hex);
    setPlatformFeeProblem(!(feePaymentTime > 0))
  }

  /* async function loadSettings() {
    setCurrentJob('Loading data...')
    const settings = await AdminService.getSettings()
    const conversionRate =  settings.data.find(setting => setting.settingId === 'conversion_rate_hidden')
    setDexoUsdRate(conversionRate.settingValue || 0.0000025)
    setCurrentJob('')
  } */

  async function loadInvestments() {
    const fn = {
      contractAddress,
      functionName: "getAllUserPackages",
      abi: contractAbi,
      params: {
        user: user.attributes.ethAddress,
      },
    };
    const _userInfo = await Moralis.executeFunction(fn);
    let tempPackages = [];
    _userInfo.map((pkg) => {
      const stakeTime = parseInt(pkg.stakeTime._hex);
      tempPackages.push({
        date: stakeTime,
        isActive: pkg.isActive,
        packageIndex: parseInt(pkg.packageIndex._hex)
      });
      return pkg;
    });
    tempPackages.reverse();
    setPackagesHistory([...tempPackages]);
  }

  /* async function getMinUsdToStakeDexo () {
    const fn = {
      contractAddress,
      functionName: "minAmountToStakeWithDexo",
      abi: contractAbi
    };
    const res = await Moralis.executeFunction(fn)
    setMinUsdToStakeDexo(parseInt(res._hex))
  }  */

  async function loadUserTokens() {
    const _tokens = await Moralis.Web3API.account.getTokenBalances({ chain: chainName})
    let tempTokens = {usdt: 0, dexo: 0, onesd: 0}
    _tokens.map(tkn => {
      if (tkn.token_address === usdtContract.toLowerCase()) tempTokens.usdt = new BigNumber(tkn.balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
      if (tkn.token_address === dexoContract.toLowerCase()) tempTokens.dexo = new BigNumber(tkn.balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
      if (tkn.token_address === oneSdContract.toLowerCase()) tempTokens.onesd =  new BigNumber(tkn.balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
      return null
    })
    setUserTokens({...tempTokens})
  }

  async function selectPackage(pkgId, usdtAmount, onesdAmount, dexoAmount) {
    const amount = usdtAmount * 2;
    const fn = {
      contractAddress,
      functionName: "stake",
      abi: contractAbi,
      params: {
        packageIndex: pkgId,
        userAddress: user.attributes.ethAddress,
        usdtAmount: usdtAmount,
        dexoAmount: dexoAmount,
        oneSDAmount: onesdAmount,
      },
    };
    let tempPkgSt = packagesStatus.map(pkg => {
      pkg.pending = pkgId === pkg.id ? true : pkg.pending
      return pkg
    })
    setPackagesStatus(tempPkgSt)
    let preActId
    try {
      const preAct = await StakingService.preActivatePackage(user.attributes.sessionToken, referral, activeCurrency, amount)
      if (preAct.data === 'error') {
        onFlash({message: 'There was an error. please contact admin', type:'danger'})
        throw new Error('Invalid data')
      }
      preActId = preAct.data
      const res = await Moralis.executeFunction(fn)
      await Promise.all([
        StakingService.activatePackage(res.hash, user.attributes.sessionToken, referral, user.attributes.ethAddress, activeCurrency, amount, preActId),
        res.wait()
      ])
      tempPkgSt = packagesStatus.map(pkg => {
        if(pkgId === pkg.id) {
          pkg.pending = pkgId === pkg.id ? false : pkg.pending
        }
        return pkg
      })
    } catch (err) {
      if (err?.data?.message)
        onFlash({message: err.data.message, type:'danger'})
      await StakingService.removePreActivation(user.attributes.sessionToken, preActId)
      console.log(err)
    } finally {
      tempPkgSt = packagesStatus.map(pkg => {
        pkg.pending = pkgId === pkg.id ? false : pkg.pending
        return pkg
      })
      reloadData()
      setPackagesStatus(tempPkgSt)
    }
  }

  async function checkAllowance() {
    const sharedParams = {
      functionName: "allowance",
      params: {owner: user.attributes.ethAddress,spender: contractAddress}
    }
    const fnUsdt = {...sharedParams,contractAddress: usdtContract,abi: usdtAbi}
    const fnDexo = {...sharedParams,contractAddress: dexoContract,abi: dexoAbi}
    const fnOneSd = {...sharedParams,contractAddress: oneSdContract,abi: oneSdAbi}
    try {
      const [_usdtAllowance,_dexoAllowance,_onesdAllowance] = await Promise.all([
        Moralis.executeFunction(fnUsdt),
        Moralis.executeFunction(fnDexo),
        Moralis.executeFunction(fnOneSd),
      ])
      const usdtCurrentAllowance = new BigNumber(_usdtAllowance._hex, 16).dividedBy(new BigNumber(10).pow(18)).toNumber()
      const dexoCurrentAllowance = new BigNumber(_dexoAllowance._hex, 16).dividedBy(new BigNumber(10).pow(18)).toNumber()
      const onesdCurrentAllowance = new BigNumber(_onesdAllowance._hex, 16).dividedBy(new BigNumber(10).pow(18)).toNumber()
      setAllowance({
        usdt: usdtCurrentAllowance,
        dexo: dexoCurrentAllowance,
        onesd: onesdCurrentAllowance
      })
    } catch(err) {
      console.log(err)
    }
  }

  async function approveToken(
    pkgUsdtPrice,
    pkgOnesdPrice,
    pkgDexoPrice,
    usdtAllowanceShort,
    onesdAllowanceShort,
    dexoAllowanceShort
  ) {

    let newAllowance = {...allowance}

    if (usdtAllowanceShort > 0) {
      const bigAmount = new BigNumber(pkgUsdtPrice).multipliedBy(
        new BigNumber(10).pow(18)
      );
      const fn = {
        functionName: "approve",
        params: {
          spender: contractAddress,
          amount: bigAmount.toFixed(),
        },
        contractAddress: usdtContract,
        abi: usdtAbi,
      };
      setCurrentJob("Please approve the operation...");
      try {
        const res = await Moralis.executeFunction(fn);
        setCurrentJob("Please wait...");
        const result = await res.wait();
        if (result.status === 1)
          newAllowance = { ...newAllowance, usdt: pkgUsdtPrice }
      } catch (err) {
        console.log(err);
      } finally {
        setCurrentJob("");
      }
    }

    if (onesdAllowanceShort > 0) {
      const bigAmount = new BigNumber(pkgOnesdPrice).multipliedBy(
        new BigNumber(10).pow(18)
      );
      const fn = {
        functionName: "approve",
        params: {
          spender: contractAddress,
          amount: bigAmount.toFixed(),
        },
        contractAddress: oneSdContract,
        abi: oneSdAbi,
      };
      setCurrentJob("Please approve the operation...");
      try {
        const res = await Moralis.executeFunction(fn);
        setCurrentJob("Please wait...");
        const result = await res.wait();
        if (result.status === 1)
          newAllowance = { ...newAllowance, onesd: pkgOnesdPrice }
      } catch (err) {
        console.log(err);
      } finally {
        setCurrentJob("");
      }
    }

    if (dexoAllowanceShort > 0) {
      const bigAmount = new BigNumber(pkgDexoPrice).multipliedBy(
        new BigNumber(10).pow(18)
      );
      const fn = {
        functionName: "approve",
        params: {
          spender: contractAddress,
          amount: bigAmount.toFixed(),
        },
        contractAddress: dexoContract,
        abi: dexoAbi,
      };
      setCurrentJob("Please approve the operation...");
      try {
        const res = await Moralis.executeFunction(fn);
        setCurrentJob("Please wait...");
        const result = await res.wait();
        if (result.status === 1)
          newAllowance = { ...newAllowance, dexo: pkgDexoPrice }
      } catch (err) {
        console.log(err);
      } finally {
        setCurrentJob("");
      }
    }
    setAllowance({ ...newAllowance});
  }

  return (
    <div className="container">
      {platformFeeProblem && <PlatformFee moralisObj={moralisObj} />}
      <h3 className="st-header">Select an investment package</h3>
      <div className="dexo-switch">
        <div className="tokens-description">
          Payment will be made with 50% USDT, and for the <br /> remaining 50%,
          you can choose between DEXO or 1SD
        </div>
        <div className="token-options">
          <RadioButton disabled checked>
            USDT
          </RadioButton>
          <span className="vsep" />
          <RadioButton
            checked={activeCurrency === "dexo"}
            onRbClick={() => setActiveCurrency("dexo")}
          >
            DEXO
          </RadioButton>
          <RadioButton
            checked={activeCurrency === "onesd"}
            onRbClick={() => setActiveCurrency("onesd")}
          >
            1SD
          </RadioButton>
        </div>
      </div>
      <div className="empty-box mb-3">
        <div className="packages">
          {dynamicPackages.map((pkg, index) =>
            pkg.active ? (
              <React.Fragment key={"pkg" + pkg.id}>
                <Package
                  id={pkg.id}
                  title={pkg.title}
                  price={pkg.price}
                  usdtPrice={pkg.price/2}
                  onesdPrice={activeCurrency === 'onesd' ? pkg.price/2 : 0}
                  dexoPrice={activeCurrency === 'dexo' ? new BigNumber(pkg.price).dividedBy(new BigNumber(2)).dividedBy(new BigNumber(dexoUsdRate)).toNumber() : 0}
                  description1={pkg.description1}
                  description2={pkg.description2}
                  activeCurrency={activeCurrency}
                  stakePending={packagesStatus[index].pending}
                  userTokens={userTokens}
                  allowance={allowance}
                  platformFeeProblem={platformFeeProblem}
                  onApproveToken={approveToken}
                  onPackageSelect={selectPackage}
                />
              </React.Fragment>
            ) : null
          )}
        </div>
      </div>
      <div className="white-box mb-3" id="tour_packages_history">
        <h3>Your Investments</h3>
        {packagesHistory.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Activation Date</th>
                <th>Package</th>
                <th>End Date of the Contract</th>
              </tr>
            </thead>
            <tbody>
              {packagesHistory.map((stk, indx) => (
                <tr key={"pkgh" + indx}>
                  <td>
                    {stk.date > 0
                      ? moment(stk.date * 1000).format("MMM D YYYY")
                      : null}
                  </td>
                  <td>
                    {dynamicPackages[0]
                      ? `${dynamicPackages[stk.packageIndex].title} ($${
                          dynamicPackages[stk.packageIndex].price
                        })`
                      : "-"}
                  </td>
                  <td>
                    {stk.isActive ? (
                      <button className="sbtn sbtn-disabled">
                        {moment(stk.date * 1000)
                          .add(25, "month")
                          .format("MMM D YYYY")}
                      </button>
                    ) : (
                      "Ended"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: "center" }}>No investments yet</div>
        )}
      </div>
    </div>
  );
}

export default Packages;